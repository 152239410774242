var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('DashboardLayout',{scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("Users")]},proxy:true}])},[_c('v-row',[(_vm.refreshingUsers)?_c('v-col',{staticClass:"pt-0 pt-sm-4",attrs:{"cols":"12"}},[_c('v-row',[_c('v-progress-linear',{attrs:{"indeterminate":"","color":"accent"}})],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"list-item-avatar-two-line@6"}})],1)],1)],1):_c('v-col',[_c('v-expansion-panels',{attrs:{"hover":"","popout":"","disabled":_vm.refreshingUsers || _vm.creatingUser || _vm.updatingUser || _vm.deletingUser},model:{value:(_vm.expansionPanel),callback:function ($$v) {_vm.expansionPanel=$$v},expression:"expansionPanel"}},[_c('v-expansion-panel',{staticClass:"mb-4"},[_c('v-expansion-panel-header',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"flex-grow-0 mr-4"},[_vm._v("mdi-account-plus-outline")]),_vm._v(" Create New User ")],1),_c('v-expansion-panel-content',{staticClass:"py-2"},[_c('v-form',{ref:"newUserForm",attrs:{"disabled":_vm.creatingUser}},[_c('v-text-field',{attrs:{"label":"Name *","rules":[function (val) { return val.length > 0 || 'Cannot be empty'; }]},model:{value:(_vm.newUser.name),callback:function ($$v) {_vm.$set(_vm.newUser, "name", $$v)},expression:"newUser.name"}}),_c('v-text-field',{attrs:{"label":"Username *","rules":[function (val) { return val.length > 0 || 'Cannot be empty'; }]},model:{value:(_vm.newUser.username),callback:function ($$v) {_vm.$set(_vm.newUser, "username", $$v)},expression:"newUser.username"}}),_c('PasswordField',{attrs:{"label":"Password *","rules":[function (val) { return val.length > 0 || 'Cannot be empty'; }]},model:{value:(_vm.newUser.password),callback:function ($$v) {_vm.$set(_vm.newUser, "password", $$v)},expression:"newUser.password"}}),_c('v-text-field',{attrs:{"label":"Email *","rules":[
                  function (val) { return val.length > 0 || 'Cannot be empty'; },
                  function (val) { return _vm.helperUtils.validateEmail(val) || 'Invalid Email'; } ]},model:{value:(_vm.newUser.email),callback:function ($$v) {_vm.$set(_vm.newUser, "email", $$v)},expression:"newUser.email"}}),_c('v-select',{attrs:{"label":"User Type *","items":_vm.userTypes},model:{value:(_vm.newUser.type),callback:function ($$v) {_vm.$set(_vm.newUser, "type", $$v)},expression:"newUser.type"}}),_c('v-row',{staticClass:"pa-2 pb-1"},[_c('v-btn',{staticClass:"mx-1",attrs:{"depressed":"","color":"primary","loading":_vm.creatingUser},on:{"click":_vm.createNewUser}},[_vm._v("Create")]),_c('v-btn',{staticClass:"mx-1",attrs:{"depressed":""},on:{"click":function($event){_vm.expansionPanel = null}}},[_vm._v("Close")])],1)],1)],1)],1),_vm._l((_vm.users),function(user){return _c('v-expansion-panel',{key:user._id},[_c('v-expansion-panel-header',[_vm._v(" "+_vm._s(user.name)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:("ml-4 mr-2 flex-grow-0 " + (user.type !== 'admin' ? 'd-none' : ''))},'v-icon',attrs,false),on),[_vm._v(" mdi-account-supervisor-circle-outline ")])]}}],null,true)},[_vm._v(" Admin ")]),_c('div',{staticClass:"hidden-xs-only"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ml-4"},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(user.username)+" ")])]}}],null,true)},[_vm._v(" Username ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ml-4"},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(user.email)+" ")])]}}],null,true)},[_vm._v(" Email ")])],1)],1),_c('v-expansion-panel-content',{staticClass:"py-2"},[(_vm.selectedUser)?_c('v-form',{ref:"editUserForms",refInFor:true,attrs:{"user-id":user._id,"disabled":_vm.updatingUser || _vm.deletingUser}},[_c('v-text-field',{attrs:{"label":"Name *","rules":[function (val) { return val.length > 0 || 'Cannot be empty'; }]},model:{value:(_vm.selectedUser.name),callback:function ($$v) {_vm.$set(_vm.selectedUser, "name", $$v)},expression:"selectedUser.name"}}),_c('v-text-field',{attrs:{"label":"Username *","rules":[function (val) { return val.length > 0 || 'Cannot be empty'; }]},model:{value:(_vm.selectedUser.username),callback:function ($$v) {_vm.$set(_vm.selectedUser, "username", $$v)},expression:"selectedUser.username"}}),_c('PasswordField',{attrs:{"label":"New Password"},model:{value:(_vm.selectedUser.password),callback:function ($$v) {_vm.$set(_vm.selectedUser, "password", $$v)},expression:"selectedUser.password"}}),_c('v-text-field',{attrs:{"label":"Email *","rules":[
                  function (val) { return val.length > 0 || 'Cannot be empty'; },
                  function (val) { return _vm.helperUtils.validateEmail(val) || 'Invalid Email'; } ]},model:{value:(_vm.selectedUser.email),callback:function ($$v) {_vm.$set(_vm.selectedUser, "email", $$v)},expression:"selectedUser.email"}}),_c('v-select',{attrs:{"label":"User Type *","rules":[function (val) { return val.length > 0 || 'Cannot be empty'; }],"items":_vm.userTypes},model:{value:(_vm.selectedUser.type),callback:function ($$v) {_vm.$set(_vm.selectedUser, "type", $$v)},expression:"selectedUser.type"}}),_c('v-row',{staticClass:"pa-2 pb-1"},[_c('v-btn',{staticClass:"mx-1",attrs:{"depressed":"","color":"primary","loading":_vm.updatingUser},on:{"click":_vm.saveSelectedUser}},[_vm._v("Save")]),_c('v-btn',{staticClass:"mx-1",attrs:{"depressed":""},on:{"click":function($event){_vm.expansionPanel = null}}},[_vm._v("Cancel")]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-1",attrs:{"depressed":"","color":"error","loading":_vm.deletingUser},on:{"click":_vm.disableSelectedUser}},[_vm._v("Disable")])],1)],1):_vm._e()],1)],1)})],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }